<template>
    <section class="fixed mypage-contents-fixed">
        <div class="container">
            <div class="row">
                <div class="col-12 mx-auto" style="padding: 0">
                  <div class="form-title-group">
                    <div class="menu-active-mark" style="margin-top: 10px;"/>
                    <div class="form-title">
                      <h1 v-if="isEdit">求人編集</h1>
                      <h1 v-else>求人登録</h1>
                    </div>
                  </div>
                  <div class="menu-bar"/>
                    <p v-if="isEdit" class="form-text">登録済み求人の編集を行うことができます。</p>
                    <p v-else class="form-text">新規求人の登録ができます。求人は登録するとすぐに公開されます。求人は最大3件の登録が可能です。</p>
                    <div class="form-group">
                        <div class="form-group-title-group">
                            <label>求人タイトル</label>
                            <p class="required-label">必須</p>
                        </div>
                        <input type="text" class="form-control" required v-model="title">
                    </div>
                    <div class="form-group">
                        <div class="form-group-title-group">
                            <label>職種</label>
                            <p class="required-label" style="margin-left:0; ">必須</p>
                        </div>
                      <div class="form-control form-select form-mulch" style="margin-top: 2px;">
                        <ul class="no-ul-list">
                          <li class="inline_block"  style="width: auto; border: none" v-for="jobCategoryType of jobCategoryTypes" :key="jobCategoryType">
                            <input type="radio" :id="`jobCategory${jobCategoryType}`" :value="jobCategoryType" v-model="jobCategory" />
                            <label :for="`jobCategory${jobCategoryType}`" style="margin-top: 0px;">{{getJobCategoryText(jobCategoryType)}}</label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  <div class="form-group">
                    <div class="form-group-title-group">
                      <label>求人の特徴</label>
                      <p class="non-required-label"></p>
                    </div>
                    <div class="form-control form-select form-mulch" style="height: auto; padding-bottom: 0; margin-top: -7px;">
                      <ul class="no-ul-list" style="margin-bottom: 0; margin-left: -10px;">
                        <li class="inline_block" v-for="jobFeatureType of jobFeatureTypes" :key="jobFeatureType">
                          <input class="checkbox-custom" type="checkbox" :id="`jobFeature${jobFeatureType}`" :value="jobFeatureType" v-model="jobFeatures">
                          <label class="checkbox-custom-label form-select-2" :for="`jobFeature${jobFeatureType}`">{{getJobFeatureText(jobFeatureType)}}</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                    <div class="form-group">
                        <div class="form-group-title-group">
                            <label>業務内容</label>
                            <p class="required-label">必須</p>
                        </div>
                        <input type="text" class="form-control" placeholder="ゲーム制作" required v-model="jobDetail">
                    </div>
                    <div class="form-group">
                        <div class="form-group-title-group">
                            <label>必須スキル</label>
                            <p class="required-label">必須</p>
                        </div>
                        <input type="text" class="form-control" placeholder="フロントエンド開発経験3年以上" required v-model="requiredSkill">
                    </div>
                    <div class="form-group">
                        <div class="form-group-title-group">
                            <label>推奨スキル</label>
                            <p class="non-required-label"></p>
                        </div>
                        <input type="text" class="form-control" placeholder="リーダー経験" required v-model="recommendedSkill">
                    </div>
                    <div class="form-group">
                        <div class="form-group-title-group">
                            <label>勤務地</label>
                            <p class="required-label">必須</p>
                        </div>
                        <input type="text" class="form-control" placeholder="東京" required v-model="workLocation">
                    </div>
                    <div class="form-group">
                        <div class="form-group-title-group">
                            <label>最寄駅</label>
                            <p class="non-required-label"></p>
                        </div>
                        <input type="text" class="form-control" placeholder="東京駅" required v-model="nearestStation">
                    </div>
                    <div class="form-group">
                        <div class="form-group-title-group">
                            <label>想定給与</label>
                            <p class="required-label">必須</p>
                        </div>
                        <input type="text" class="form-control" placeholder="400万円" required v-model="estimatedSalary">
                    </div>
                    <div class="form-group">
                        <div class="form-group-title-group">
                            <label>賞与</label>
                            <p class="non-required-label"></p>
                        </div>
                        <div class="form-group-input-contents">
                            <input type="text" class="form-control" placeholder="年1回" v-model="bonus">
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="form-group-title-group">
                            <label>評価・昇給制度</label>
                            <p class="non-required-label"></p>
                        </div>
                        <div class="form-group-input-contents">
                            <input type="text" class="form-control" placeholder="年1回" v-model="evaluationSystem">
                        </div>
                    </div>
                  <div class="form-group">
                    <div class="form-group-title-group">
                      <label>週休制</label>
                      <p class="required-label" style="margin-left:0;">必須</p>
                    </div>
                    <div class="form-control form-select form-mulch" style="margin-top: 2px; height: auto; padding-bottom: 0; margin-top: -7px;">
                      <ul class="no-ul-list" style="margin-bottom: 0;">
                        <li class="inline_block"  style="width: auto; border: none" v-for="weeklyHolidaySystemType of weeklyHolidaySystemTypes" :key="weeklyHolidaySystemType">
                          <input type="radio" :id="`weeklyHolidaySystem${weeklyHolidaySystemType}`" :value="weeklyHolidaySystemType" v-model="weeklyHolidaySystem" />
                          <label :for="`weeklyHolidaySystem${weeklyHolidaySystemType}`">{{getWeeklyHolidaySystemText(weeklyHolidaySystemType)}}</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-group-title-group">
                      <label>雇用形態</label>
                      <p class="required-label" style="margin-left:0;">必須</p>
                    </div>
                    <div class="form-control form-select form-mulch" style="margin-top: 2px; height: auto; padding-bottom: 0; margin-top: -7px;">
                      <ul class="no-ul-list" style="margin-bottom: 0;">
                        <li class="inline_block"  style="width: auto; border: none" v-for="employmentStatusType of employmentStatusTypes" :key="employmentStatusType">
                          <input type="radio" :id="`employmentStatus${employmentStatusType}`" :value="employmentStatusType" v-model="employmentStatus" />
                          <label :for="`employmentStatus${employmentStatusType}`">{{getEmploymentStatusText(employmentStatusType)}}</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-group-title-group">
                      <label>休暇</label>
                      <p class="non-required-label"></p>
                    </div>
                    <div class="form-control form-select form-mulch" style="height: auto; padding-bottom: 0; margin-top: -7px;">
                      <ul class="no-ul-list" style="margin-bottom: 0; margin-left: -10px;">
                        <li class="inline_block" v-for="vacationType of vacationTypes" :key="vacationType">
                          <input class="checkbox-custom" type="checkbox" :id="`vacation${vacationType}`" :value="vacationType" v-model="vacations">
                          <label class="checkbox-custom-label form-select-2" :for="`vacation${vacationType}`">{{getVacationText(vacationType)}}</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                    <div class="form-group">
                      <div class="form-group-title-group">
                        <label>福利厚生</label>
                        <p class="non-required-label"></p>
                      </div>
                        <div class="form-group-input-contents">
                            <textarea class="form-control" placeholder="・交通費全額支給" v-model="welfareSystems"></textarea>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="form-group-title-group">
                            <label>勤務時間</label>
                            <p class="required-label">必須</p>
                        </div>
                      <div class="form-group-input-contents">
                        <input type="time" class="form-control form-control-time" required v-model="workingStartTime"><div>〜</div><input type="time" class="form-control form-control-time" required v-model="workingEndTime">
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="form-group-title-group">
                            <label>業界歴</label>
                            <p class="required-label">必須</p>
                        </div>
                        <input type="number" class="form-control" required v-model="industryHistory">
                    </div>
                    <div class="form-group">
                        <div class="form-group-title-group">
                            <label>求める人物像</label>
                            <p class="non-required-label"></p>
                        </div>
                        <div class="form-group-input-contents">
                            <textarea class="form-control" placeholder="" v-model="desiredCandidateProfile"></textarea>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="form-group-title-group">
                            <label>備考</label>
                            <p class="non-required-label"></p>
                        </div>
                        <div class="form-group-input-contents">
                            <textarea class="form-control" placeholder="例)" v-model="remarks"></textarea>
                        </div>
                    </div>
                    <template v-if="isSales">
                      <div class="form-group">
                        <div class="form-group-title-group">
                          <label>営業スタイル</label>
                          <p class="non-required-label"></p>
                        </div>
                        <div class="form-control form-select form-mulch" style="height: auto; padding-bottom: 0; margin-top: -7px;">
                          <ul class="no-ul-list" style="margin-bottom: 0; margin-left: -10px;">
                            <li class="inline_block" v-for="salesStyleType of salesStyleTypes" :key="salesStyleType">
                              <input class="checkbox-custom" type="checkbox" :id="`salesStyle${salesStyleType}`" :value="salesStyleType" v-model="salesJobInfo.salesStyles">
                              <label class="checkbox-custom-label form-select-2" :for="`salesStyle${salesStyleType}`">{{getSalesStyleText(salesStyleType)}}</label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-group-title-group">
                          <label>案件 or 人材</label>
                          <p class="required-label" style="margin-left:0;">必須</p>
                        </div>
                        <div class="form-control form-select form-mulch" style="margin-top: 2px; height: auto; padding-bottom: 0; margin-top: -7px;">
                          <ul class="no-ul-list" style="margin-bottom: 0;">
                            <li class="inline_block"  style="width: auto; border: none" v-for="handledSalesType of handledSalesTypes" :key="handledSalesType">
                              <input type="radio" :id="`handledSales${handledSalesType}`" :value="handledSalesType" v-model="salesJobInfo.handledSales" />
                              <label :for="`handledSales${handledSalesType}`">{{getHandledSalesText(handledSalesType)}}</label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-group-title-group">
                          <label>役職</label>
                          <p class="required-label" style="margin-left:0;">必須</p>
                        </div>
                        <div class="form-control form-select form-mulch" style="margin-top: 2px; height: auto; padding-bottom: 0; margin-top: -7px;">
                          <ul class="no-ul-list" style="margin-bottom: 0;">
                            <li class="inline_block"  style="width: auto; border: none" v-for="salesJobPositionType of salesJobPositionTypes" :key="salesJobPositionType">
                              <input type="radio" :id="`salesJobPosition${salesJobPositionType}`" :value="salesJobPositionType" v-model="salesJobInfo.salesJobPosition" />
                              <label :for="`salesJobPosition${salesJobPositionType}`">{{getSalesJobPositionText(salesJobPositionType)}}</label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-group-title-group">
                          <label>得意業界</label>
                          <p class="non-required-label"></p>
                        </div>
                        <div class="form-control form-select form-mulch" style="height: auto; padding-bottom: 0; margin-top: -7px;">
                          <ul class="no-ul-list" style="margin-bottom: 0; margin-left: -10px;">
                            <li class="inline_block" v-for="strengthIndustryType of strengthIndustryTypes" :key="strengthIndustryType">
                            <input class="checkbox-custom" type="checkbox" :id="`strengthIndustry${strengthIndustryType}`" :value="strengthIndustryType" v-model="salesJobInfo.strengthIndustries">
                            <label class="checkbox-custom-label form-select-2" :for="`strengthIndustry${strengthIndustryType}`">{{getStrengthIndustryText(strengthIndustryType)}}</label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="isEngineer">
                      <div class="form-group">
                        <div class="form-group-title-group">
                          <label>技術領域</label>
                          <p class="required-label" style="margin-left:0;">必須</p>
                        </div>
                        <div class="form-control form-select form-mulch" style="margin-top: 2px; height: auto; padding-bottom: 0; margin-top: -7px;">
                          <ul class="no-ul-list" style="margin-bottom: 0;">
                            <li class="inline_block"  style="width: auto; border: none" v-for="technicalFieldType of technicalFieldTypes" :key="technicalFieldType">
                              <input type="radio" :id="`technicalField${technicalFieldType}`" :value="technicalFieldType" v-model="engineerJobInfo.technicalField" />
                              <label :for="`technicalField${technicalFieldType}`">{{getTechnicalFieldText(technicalFieldType)}}</label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-group-title-group">
                          <label>役職</label>
                          <p class="required-label" style="margin-left:0;">必須</p>
                        </div>
                        <div class="form-control form-select form-mulch" style="margin-top: 2px; height: auto; padding-bottom: 0; margin-top: -7px;">
                          <ul class="no-ul-list" style="margin-bottom: 0;">
                            <li class="inline_block"  style="width: auto; border: none" v-for="engineerJobPositionType of engineerJobPositionTypes" :key="engineerJobPositionType">
                              <input type="radio" :id="`engineerJobPosition${engineerJobPositionType}`" :value="engineerJobPositionType" v-model="engineerJobInfo.engineerJobPosition" />
                              <label :for="`engineerJobPosition${engineerJobPositionType}`">{{getEngineerJobPositionText(engineerJobPositionType)}}</label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </template>
                    <div v-if="hasValidationError" class="error-area">
                        <template v-for="(value, key) in validationErrors">
                            <p v-for="item in value" :key="key + item.validationText">
                                ・{{"(" + getFormName(key) + ")" + item.validationText}}
                            </p>
                        </template>
                    </div>
                    <p v-if="isChanged" class="result-text-success">求人情報の更新が完了しました。</p>
                    <div>
                        <button type="submit" class="btn-md full-width pop-login form-button orange-btn" :disabled="!isRegisterEnabled" @click="onClickSendButton">{{isEdit ? "求人情報を更新する" : "求人情報を登録する"}}</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>

    import {
        EmploymentStatus, EngineerJobPosition,
        getEmploymentStatusText, getEngineerJobPositionText, getHandledSalesText,
        getJobCategoryText,
        getJobFeatureText, getSalesJobPositionText, getSalesStyleText, getStrengthIndustryText, getTechnicalFieldText,
        getVacationText,
        getWeeklyHolidaySystemText, HandledSales,
        JobCategory,
        JobFeature, SalesJobPosition, SalesStyle, StrengthIndustry, TechnicalField,
        Vacation,
        WeeklyHolidaySystem
    } from "../../../common/enum";

    const formMapping = {
        title: "タイトル",
        jobCategory: "職種",
        jobFeatures: "求人の特徴",
        jobDetail: "業務内容",
        requiredSkill: "必須スキル",
        recommendedSkill: "推奨スキル",
        workLocation: "勤務地",
        nearestStation: "最寄駅",
        estimatedSalary: "想定給与",
        bonus: "賞与",
        evaluationSystem: "評価・昇給制度",
        weeklyHolidaySystem: "週休制",
        employmentStatus: "雇用形態",
        vacations: "休暇",
        welfareSystems: "福利厚生",
        workingStartTime: "勤務開始時間",
        workingEndTime: "勤務終了時間",
        industryHistory: "業界歴",
        desiredCandidateProfile: "求める人物像",
        remarks: "備考",
        salesStyles: "営業スタイル",
        handledSales: "案件 or 人材",
        salesJobPosition: "役職",
        strengthIndustries: "得意業界",
        technicalField: "技術領域",
        engineerJobPosition: "役職",
    };

    export default {
        name: "JobInfoForm",
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            jobInfoData: {
                type: Object,
                default: () => {}
            },
            validationErrors: {
                type: Object,
                default: () => {}
            },
            isChanged: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                title: this.jobInfoData.title || "",
                jobCategory: this.jobInfoData.jobCategory || JobCategory.Engineer,
                jobFeatures: this.jobInfoData.jobFeatures || [],
                jobDetail: this.jobInfoData.jobDetail || "",
                requiredSkill: this.jobInfoData.requiredSkill || "",
                recommendedSkill: this.jobInfoData.recommendedSkill || "",
                workLocation: this.jobInfoData.workLocation || "",
                nearestStation: this.jobInfoData.nearestStation || "",
                estimatedSalary: this.jobInfoData.estimatedSalary || "",
                bonus: this.jobInfoData.bonus || "",
                evaluationSystem: this.jobInfoData.evaluationSystem || "",
                weeklyHolidaySystem: this.jobInfoData.weeklyHolidaySystem || WeeklyHolidaySystem.FullTwoDaysOff,
                employmentStatus: this.jobInfoData.employmentStatus || EmploymentStatus.RegularEmployee,
                vacations: this.jobInfoData.vacations || [],
                welfareSystems: this.jobInfoData.welfareSystems || "",
                workingStartTime: this.jobInfoData.workingStartTime || "",
                workingEndTime: this.jobInfoData.workingEndTime || "",
                industryHistory: this.jobInfoData.industryHistory || 0,
                desiredCandidateProfile: this.jobInfoData.desiredCandidateProfile || "",
                remarks: this.jobInfoData.remarks || "",
                salesJobInfo: this.jobInfoData.salesJobInfo || {
                    salesStyles: [],
                    handledSales: HandledSales.Project,
                    salesJobPosition: SalesJobPosition.Members,
                    strengthIndustries: []
                },
                engineerJobInfo: this.jobInfoData.engineerJobInfo || {
                    technicalField: TechnicalField.FrontEnd,
                    engineerJobPosition: EngineerJobPosition.Members
                }
            }
        },

        computed: {
            jobCategoryTypes: {
                get() {
                    return Object.values(JobCategory);
                }
            },
            jobFeatureTypes: {
                get() {
                    return Object.values(JobFeature);
                }
            },
            weeklyHolidaySystemTypes: {
                get() {
                    return Object.values(WeeklyHolidaySystem);
                }
            },
            employmentStatusTypes: {
                get() {
                    return Object.values(EmploymentStatus);
                }
            },
            vacationTypes: {
                get() {
                    return Object.values(Vacation);
                }
            },
            salesStyleTypes: {
                get() {
                    return Object.values(SalesStyle);
                }
            },
            handledSalesTypes: {
                get() {
                    return Object.values(HandledSales);
                }
            },
            salesJobPositionTypes: {
                get() {
                    return Object.values(SalesJobPosition);
                }
            },
            strengthIndustryTypes: {
                get() {
                    return Object.values(StrengthIndustry);
                }
            },
            technicalFieldTypes: {
                get() {
                    return Object.values(TechnicalField);
                }
            },
            engineerJobPositionTypes: {
                get() {
                    return Object.values(EngineerJobPosition);
                }
            },
            isSales: {
                get() {
                    return this.jobCategory === JobCategory.Sales;
                }
            },
            isEngineer: {
                get() {
                    return this.jobCategory === JobCategory.Engineer;
                }
            },
            hasValidationError: {
                get() {
                    return Object.values(this.validationErrors).length > 0;
                }
            },
            isRegisterEnabled: {
                get() {
                    // return this.username && this.password1 && this.password2;
                    return true;
                },
            }
        },
        methods: {
            getFormName: function (key) {
                return formMapping[key];
            },
            getJobCategoryText: function (jobCategoryType) {
                return getJobCategoryText(jobCategoryType);
            },
            getJobFeatureText: function (jobFeatureType) {
                return getJobFeatureText(jobFeatureType);
            },
            getWeeklyHolidaySystemText: function (weeklyHolidaySystemType) {
                return getWeeklyHolidaySystemText(weeklyHolidaySystemType);
            },
            getEmploymentStatusText: function (employmentStatusType) {
                return getEmploymentStatusText(employmentStatusType);
            },
            getVacationText: function (vacationType) {
                return getVacationText(vacationType);
            },
            getSalesStyleText: function (salesStyleType) {
                return getSalesStyleText(salesStyleType);
            },
            getHandledSalesText: function (handledSalesType) {
                return getHandledSalesText(handledSalesType);
            },
            getSalesJobPositionText: function (salesJobPositionType) {
                return getSalesJobPositionText(salesJobPositionType);
            },
            getStrengthIndustryText: function (vacationType) {
                return getStrengthIndustryText(vacationType);
            },
            getTechnicalFieldText: function (technicalFieldType) {
                return getTechnicalFieldText(technicalFieldType);
            },
            getEngineerJobPositionText: function (engineerJobPositionType) {
                return getEngineerJobPositionText(engineerJobPositionType);
            },
            async onClickSendButton() {
                this.$emit("on-click-send-button", {
                    id: this.jobInfoData.id,
                    title: this.title,
                    jobCategory: Number(this.jobCategory),
                    jobFeatures: this.jobFeatures,
                    jobDetail: this.jobDetail,
                    requiredSkill: this.requiredSkill,
                    recommendedSkill: this.recommendedSkill,
                    workLocation: this.workLocation,
                    nearestStation: this.nearestStation,
                    estimatedSalary: this.estimatedSalary,
                    bonus: this.bonus,
                    evaluationSystem: this.evaluationSystem,
                    weeklyHolidaySystem: Number(this.weeklyHolidaySystem),
                    employmentStatus: Number(this.employmentStatus),
                    vacations: this.vacations,
                    welfareSystems: this.welfareSystems,
                    workingStartTime: this.workingStartTime,
                    workingEndTime: this.workingEndTime,
                    industryHistory: Number(this.industryHistory),
                    styles: this.styles,
                    strengthIndustries: this.strengthIndustries,
                    desiredCandidateProfile: this.desiredCandidateProfile,
                    remarks: this.remarks,
                    salesJobInfo: {
                        salesStyles: this.salesJobInfo.salesStyles,
                        handledSales: Number(this.salesJobInfo.handledSales),
                        salesJobPosition: Number(this.salesJobInfo.salesJobPosition),
                        strengthIndustries: this.salesJobInfo.strengthIndustries
                    },
                    engineerJobInfo: {
                        technicalField: Number(this.engineerJobInfo.technicalField),
                        engineerJobPosition: Number(this.engineerJobInfo.engineerJobPosition)
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>
